.App {
  text-align: center;
}

.green-gradient {
  background: linear-gradient(-45deg, #23a6d5, #23d5ab);
}
a {
  text-decoration: none;
}
/* unvisited link */
a:link {
  color: #000;
}

/* visited link */
a:visited {
  color: #000;
}

/* mouse over link */
a:hover {
  color: #00b66c;
}

/* selected link */
a:active {
  color: #000;
}

#qrcode img {
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto 1rem auto;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0 0.2rem;
  }
}
.location-search-container {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  user-select: text;
  pointer-events: all;
  color: rgb(84, 84, 84);
  width: 100%;
  display: flex;
  border-left-style: solid;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-color: rgb(238, 238, 238);
  border-bottom-color: rgb(238, 238, 238);
  border-left-color: rgb(238, 238, 238);
  font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  transition-duration: 200ms;
  background-color: rgb(238, 238, 238);
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  box-sizing: border-box;
  overflow: hidden;
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);
  transition-property: border;
  font-weight: normal;
  line-height: 24px;
  border-top-color: rgb(238, 238, 238);
  padding-left: 0px;
  padding-right: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.location-search-base {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  user-select: text;
  pointer-events: all;
  width: 100%;
  display: flex;
  font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgb(0, 0, 0);
  transition-duration: 200ms;
  background-color: rgb(238, 238, 238);
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);
  font-weight: normal;
  line-height: 24px;
  transition-property: background-color;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.location-search-input {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  user-select: text;
  pointer-events: all;
  width: 100%;
  padding-bottom: 10px;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
  font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgb(0, 0, 0);
  border-left-width: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  outline: none;
  background-color: transparent;
  padding-top: 10px;
  box-sizing: border-box;
  font-weight: normal;
  line-height: 24px;
  max-width: 100%;
  cursor: text;
  margin: 0px;
  padding-left: 14px;
  padding-right: 14px;
  caret-color: rgb(0, 0, 0);
}
.location-search-input:disabled {
  background-color: rgb(246, 246, 246);
  border-color: rgb(246, 246, 246);
  cursor: not-allowed;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-style: solid;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  margin: 0px;
}
.location-search-input:disabled::placeholder {
  color: rgb(170, 170, 170);
}
.location-search-container-disabled {
  border-color: rgb(246, 246, 246);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  border-bottom-style: none;
  box-sizing: border-box;
  padding-left: 0px;
  padding-right: 0px;
  line-height: 24px;
  margin: 0px;
  font-size: 16px;
}
pre {
  background: #eee;
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
  max-width: 80ch;
}

pre code {
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
}